import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import legacy from './legacy'
import Shipment from './Shipment'
import permissions from './Permissions'
import amazonShipmentCreation from './AmazonShipmentCreation'

Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        legacy,
        Shipment,
        permissions,
        amazonShipmentCreation,
    },
    strict: process.env.DEV,
})