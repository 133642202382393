<template>
    <div
        id="app"
        class="h-100"
        :class="[skinClasses]"
    >
        <template v-if="!authLoading">
            <app />
        </template>
        <div v-else>
            <app />
        </div>
    </div>
</template>

<style>
</style>
<script>
import useAppConfig from '@core/app-config/useAppConfig'
import App from './App.vue'

export default {
    components: {
        App,
    },
    computed: {
        authLoading() {
            return this.$auth.loading
        },
    },
    setup() {
        const { skinClasses } = useAppConfig()

        return {
            skinClasses,
        }
    },
}
</script>
