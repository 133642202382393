const CONDITION_NEW_ITEM = 'NewItem'
const CONDITION_NEW_ITEM_WITH_WARRANTY = 'NewWithWarranty'
const CONDITION_NEW_OEM = 'NewOEM'
const CONDITION_NEW_OPEN_BOX = 'NewOpenBox'
const CONDITION_USED_LIKE_NEW = 'UsedLikeNew'
const CONDITION_USED_VERY_GOOD = 'UsedVeryGood'
const CONDITION_USED_GOOD = 'UsedGood'
const CONDITION_USED_ACCEPTABLE = 'UsedAcceptable'
const CONDITION_USED_POOR = 'UsedPoor'
const CONDITION_USED_REFURBISHED = 'UsedRefurbished'
const CONDITION_COLLECTIBLE_LIKE_NEW = 'CollectibleLikeNew'
const CONDITION_COLLECTIBLE_VERY_GOOD = 'CollectibleVeryGood'
const CONDITION_COLLECTIBLE_GOOD = 'CollectibleGood'
const CONDITION_COLLECTIBLE_ACCEPTABLE = 'CollectibleAcceptable'
const CONDITION_COLLECTIBLE_POOR = 'CollectiblePoor'
const CONDITION_REFURBISHED_WARRANTY = 'RefurbishedWithWarranty'
const CONDITION_REFURBISHED = 'Refurbished'
const CONDITION_CLUB = 'Club'

const AMAZON_ITEM_CONDITIONS = {
    [CONDITION_NEW_ITEM]: 'New Item',
    [CONDITION_NEW_ITEM_WITH_WARRANTY]: 'New With Warranty',
    [CONDITION_NEW_OEM]: 'New OEM',
    [CONDITION_NEW_OPEN_BOX]: 'New Open Box',
    [CONDITION_USED_LIKE_NEW]: 'Used Like New',
    [CONDITION_USED_VERY_GOOD]: 'Used Very Good',
    [CONDITION_USED_GOOD]: 'Used Good',
    [CONDITION_USED_ACCEPTABLE]: 'Used Acceptable',
    [CONDITION_USED_POOR]: 'Used Poor',
    [CONDITION_USED_REFURBISHED]: 'Used Refurbished',
    [CONDITION_COLLECTIBLE_LIKE_NEW]: 'Collectible Like New',
    [CONDITION_COLLECTIBLE_VERY_GOOD]: 'Collectible Very Good',
    [CONDITION_COLLECTIBLE_GOOD]: 'Collectible Good',
    [CONDITION_COLLECTIBLE_ACCEPTABLE]: 'Collectible Acceptable',
    [CONDITION_COLLECTIBLE_POOR]: 'Collectible Poor',
    [CONDITION_REFURBISHED_WARRANTY]: 'Refurbished With Warranty',
    [CONDITION_REFURBISHED]: 'Refurbished',
    [CONDITION_CLUB]: 'Club',
}

export {CONDITION_NEW_ITEM, AMAZON_ITEM_CONDITIONS}