export const ShipmentFilterOptions = Object.freeze({
    STATUS: {
        ALL: 'All',
        COMPLETED: 'Completed',
        PENDING: 'Pending - All',
        PENDING_LESS: 'Pending - QTY Less',
        PENDING_MORE: 'Pending - QTY More',
    },
    TYPE: {
        ALL: 'All',
        STICKERLESS: 'Stickerless',
        NON_STICKERLESS: 'Non stickerless',
    },
    PENDING_QUANTITY: {
        ALL: 'All',
        LESS: 'Less',
        MORE: 'More',
    },
})

export const SyncStatuses = Object.freeze({
    NOT_SET: 0,
    PENDING: 1,
    COMPLETED: 2,
    FAILED: 3,
})

export const LocalStorageShipmentItemFilterKeys = Object.freeze({
    STATUS: 'shipment-items-table-show-type',
    TYPE: 'shipment-items-table-fnsku-type',
    PENDING_QTY: 'shipment-items-table-pending-qty',
    BOX: 'shipment-items-table-box',
    SEARCH: 'shipment-items-table-search',
})
export const LocalStorageSelectedBoxKey = 'selected-box-in-scanner'
export const LocalStorageProjectKey = 'active-project'
export const Units = Object.freeze({
    METRIC: 'metric',
    IMPERIAL: 'imperial',
})

export const Linnworks = Object.freeze({
    TYPE_SIMPLE: 'simple',
    TYPE_FULL_ORDER: 'fullOrder',
    SYNC_TYPE_ORDER: 'order',
    SYNC_FREQUENCY_ONCE: 'once',
    SYNC_FREQUENCY_WEEKLY: 'weekly',
    SYNC_FREQUENCY_DAILY: 'daily',
})

export const DynamicTableLocalStorageKeyBase = 'dynamic-table-layout'

export const LinnworksInventoryFieldTypes = Object.freeze({
    KEY_PRIMARY_FIELD: 'primaryField',
    KEY_DEFAULT_IMAGE: 'defaultImage',
    KEY_DEFAULT_SUPPLIER: 'defaultSupplier',
    KEY_DEFAULT_STOCK: 'defaultStock',
    KEY_DEFAULT_STOCK_LOCATION: 'defaultStockLocation',
    KEY_EXTENDED_PROPERTIES: 'extendedProperties',
    KEY_STOCK: 'stockLevels',
})

export const HideModalDefaultView = Object.freeze({
    HIDE_PROFILE_MODAL: 'profileModalToHide',
    HIDE_PROJECT_MODAL: 'projectModalToHide',
})

export const DynamicTableFieldGroupNames = Object.freeze({
    KEY_CUSTOM_FIELD: 'customField',
    KEY_DEFAULT: 'default',
    KEY_AMAZON: 'amazon',
    KEY_LINNWORKS: 'linnworks',
})

export const LocalStorageChatOpenKey = 'chatOpen'

export const FeatureLinnworksIntegration = 'linnworks_integration'
export const FeatureShipments = 'shipments'
export const FeatureInventory = 'inventory'
export const FeatureOrders = 'orders'
