<template>
    <div
        id="app"
        class="h-100"
        :class="[skinClasses]"
    >
        <template v-if="!authLoading && authenticated && shouldShowContent && requiresAuth">
            <component :is="layout">
                <router-view />
            </component>

            <scroll-to-top v-if="enableScrollToTop" />
            <global-addons />
        </template>
        <template v-else-if="!requiresAuth">
            <router-view />
        </template>
        <template v-else>
            <loading-screen />
        </template>

    </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
// This will be populated in `beforeCreate` hook
import {$themeColors, $themeBreakpoints, $themeConfig} from '@themeConfig'
import {provideToast} from 'vue-toastification/composition'
import {watch} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import {useWindowSize, useCssVar} from '@vueuse/core'

import store from '@/store'

import LoadingScreen from './components/auth/Loading.vue'
import SupportChatHelper from "@/helpers/SupportChatHelper"
import GlobalAddons from "@/components/Misc/GlobalAddons"

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
    components: {

        // Layouts
        LayoutHorizontal,
        LayoutVertical,
        LayoutFull,
        ScrollToTop,
        LoadingScreen,
        GlobalAddons,
    },
    data() {
        return {
            userProfileInterval: false,
        }
    },
    // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
    // Currently, router.currentRoute is not reactive and doesn't trigger any change
    computed: {
        authLoading() {
            return this.$auth.loading
        },
        requiresAuth() {
            return this.$auth.requiresAuth
        },
        layout() {
            if (this.$route.meta.layout === 'full') return 'layout-full'
            return `layout-${this.contentLayoutType}`
        },
        contentLayoutType() {
            return this.$store.state.appConfig.layout.type
        },
        user() {
            return store.getters.user
        },
        permissions() {
            return store.getters.permissionList
        },
        projectSettings() {
            return store.getters.projectSettings
        },
        isFullyLoaded() {
            return this.user.id > 0 && Object.keys(this.permissions).length > 0 && this.projectSettings.loaded
        },
        shouldShowContent() {
            return (this.isFullyLoaded) && !this.authLoading
        },
        helpWidgetVisible() {
            return this.$store.state.verticalMenu.isHelpVisible
        },
        authenticated() {
            return this.$auth.isAuthenticated
        },
    },
    watch: {
        helpWidgetVisible(newVal) {
            if (newVal) {
                SupportChatHelper.expandWidget()
            } else {
                SupportChatHelper.hideWidget()
            }
        },
        authenticated(newVal) {
            if (newVal) {
                this.loadProfileData()
            }
        },
    },
    beforeCreate() {
        // Set colors in theme
        const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = colors.length; i < len; i += 1) {
            $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
        }

        // Set Theme Breakpoints
        const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = breakpoints.length; i < len; i++) {
            $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
        }

        document.documentElement.setAttribute('dir', 'ltr')
    },
    setup() {
        const {skin, skinClasses} = useAppConfig()
        const {enableScrollToTop} = $themeConfig.layout

        // If skin is dark when initialized => Add class to body
        if (skin.value === 'dark') document.body.classList.add('dark-layout')

        // Provide toast for Composition API usage
        // This for those apps/components which uses composition API
        // Demos will still use Options API for ease
        provideToast({
            hideProgressBar: true,
            closeOnClick: false,
            closeButton: false,
            icon: false,
            timeout: 3000,
            transition: 'Vue-Toastification__fade',
        })

        // Set Window Width in store
        store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
        const {width: windowWidth} = useWindowSize()
        watch(windowWidth, val => {
            store.commit('app/UPDATE_WINDOW_WIDTH', val)
        })

        return {
            skinClasses,
            enableScrollToTop,
        }
    },
    created() {
        this.$http.interceptors.response.use(undefined, err => new Promise((() => {
            if (err.status === 401 && err.config) {
                this.$router.push('/login')
            }
            throw err
        })))

        this.loadProfileData()

        if (this.helpWidgetVisible) {
            SupportChatHelper.showWidget()
        } else {
            SupportChatHelper.hideWidget()
        }
    },
    methods: {
        loadProfileData() {
            clearInterval(this.userProfileInterval)
            // check if user state exists, if not, fetch user and store it
            if (!store.getters.user.loaded && this.$auth.isAuthenticated) {
                this.$store.dispatch('fetchUser')
                // re-fetch user to log out if deleted or removed from project
                this.userProfileInterval = setInterval(() => {
                    this.$store.dispatch('fetchUser')
                }, 60000)
            }
        }
    }
}
</script>
