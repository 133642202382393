export default class ShipmentItem {
    static get statusPending() {
        return 0
    }

    static get statusCompleted() {
        return 1
    }

    constructor(shipmentItem) {
        for (const key of Object.keys(shipmentItem)) {
            this[key] = shipmentItem[key]
        }
        const { id, shipment_id } = shipmentItem
        this.id = id
        // eslint-disable-next-line camelcase
        this.shipment_id = shipment_id
        this.shouldPrint = false
    }

    forceCompleted() {
        return this.status === ShipmentItem.statusCompleted
    }
}
