import Vue from 'vue'
import Router from 'vue-router'
import Access from './services/Access'
import Features from './services/Features'
import Store from './store/index'
import routes from "@/routes"

Vue.use(Router)

const router = new Router({
    routes: routes,
    mode: 'history',
})

router.beforeEach(async (to, from, next) => {
    // this is for redirecting user back to last route
    const excludedRoutes = ['/login', '/', '/logout']
    if (!excludedRoutes.includes(to.path)) {
        localStorage.setItem('lastRoute', to.path)
    }

    let isValidRequest = true
    if (to.matched.some(record => record.meta.requiresAuth)) {
        Vue.prototype.$auth.requiresAuth = true
        await Vue.prototype.$auth.verifyAuthCode()
        Vue.prototype.$auth.checkIfTokenIsStored()
        if (!Vue.prototype.$auth.isAuthenticated) {
            Vue.prototype.$auth.redirectToLogin()
            return
        }
    } else {
        Vue.prototype.$auth.requiresAuth = false
    }

    // might update the permission state to be stored in local storage...
    // kind of hacky way, but since perms are not loaded on initial page load, but with ajax,
    // need to allow initial request to go though.
    const permissionsLoaded = Object.keys(Store.getters.permissionList).length > 0
    const requiresAccessCheck = to.meta.requiresAccess
    const hasAccessData = to.meta.access
    if (isValidRequest && requiresAccessCheck && hasAccessData && permissionsLoaded) {
        if (!Access.checkAccess(to.meta.access.key, to.meta.access.level)) {
            isValidRequest = false
        }
    }

    // pretty much same story as with permissions
    const {requiresFeature} = to.meta
    const {feature} = to.meta
    const featuresLoaded = Object.keys(Store.getters.availableFeatures).length > 0
    if (isValidRequest && requiresFeature && feature && featuresLoaded) {
        if (!Features.checkFeature(feature)) {
            isValidRequest = false
        }
    }

    if(isValidRequest) {
        next()
    } else {
        next('login')
    }

})

export default router
