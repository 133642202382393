import { LocalStorageShipmentItemFilterKeys, ShipmentFilterOptions } from '../constants'
import ShipmentItem from '../structures/ShipmentItem'

export default {
    state: {
        shipmentItems: [],
        ShipmentItemFilters: {
            status: ShipmentFilterOptions.STATUS.ALL,
            box: '',
            type: ShipmentFilterOptions.TYPE.ALL,
            search: '',
        },
        shipmentCustomFields: null,
    },
    getters: {
        shipmentItems: state => state.shipmentItems,
        ShipmentItemFilters: state => state.ShipmentItemFilters,
        shipmentCustomFields: state => state.shipmentCustomFields,
    },
    mutations: {
        shipment_items(state, items) {
            const shipmentItems = []
            for (const item of items) {
                shipmentItems.push(new ShipmentItem(item))
            }
            state.shipmentItems = shipmentItems
        },
        shipment_item_filters_status(state, status) {
            localStorage.setItem(LocalStorageShipmentItemFilterKeys.STATUS, status)
            state.ShipmentItemFilters.status = status
        },
        shipment_item_filters_type(state, status) {
            localStorage.setItem(LocalStorageShipmentItemFilterKeys.TYPE, status)
            state.ShipmentItemFilters.type = status
        },
        shipment_item_filters_box(state, box) {
            localStorage.setItem(LocalStorageShipmentItemFilterKeys.BOX, box)
            state.ShipmentItemFilters.box = box
        },
        shipment_item_filters_search(state, search) {
            localStorage.setItem(LocalStorageShipmentItemFilterKeys.SEARCH, search)
            state.ShipmentItemFilters.search = search
        },
        setShipmentCustomFields(state, fields) {
            state.shipmentCustomFields = fields
        },
    },
    actions: {},
}
