<template>
    <div>
        <set-default-project-settings />
    </div>
</template>

<script>
import SetDefaultProjectSettings from "@/components/Modals/SetDefaultProjectSettings"
export default {
    components: {
        SetDefaultProjectSettings,
    },
}
</script>
