import Vue from 'vue'
import Axios from 'axios'
import { LocalStorageProjectKey } from '../constants'
import Project from '../structures/Project'
import Permission from '../structures/Permission'
import SupportChatHelper from '../helpers/SupportChatHelper'

export default {
    state: {
        status: '',
        user: {
            created_at: '',
            email: '',
            email_verified_at: '',
            id: 0,
            name: '',
            updated_at: '',
            loaded: false,
            profile_image_hash: '',
            profile_image_large_url: '',
            profile_image_thumb_url: '',
            projects: [],
        },
        activeProject: new Project({ id: 0, isActive: false, name: '' }),
        permissionList: {},
        availableFeatures: [],
        projectSettings: {
            nameSet: true,
            loaded: false,
            weightUnit: 'g',
            lengthUnit: 'mm',
            enabledIntegrations: {
                linnworks: false,
                amazon: false,
            },
        },
    },
    mutations: {
        auth_error(state) {
            state.status = 'error'
        },
        logout() {
            this.$auth.logout()
        },
        activeProject(state, project) {
            localStorage.setItem(LocalStorageProjectKey, JSON.stringify(project))
            state.activeProject = project
            Vue.prototype.$http.defaults.headers.common['X-XcProject'] = project.id
        },
        user(state, user) {
            state.user = user
            // update profile image
            if (!user.profile_image_hash) {
                state.user.profile_image_large_url = '/assets/app/media/img/users/default-avatar.png'
                state.user.profile_image_thumb_url = '/assets/app/media/img/users/default-avatar_t.png'
            }
            state.user.loaded = true
            state.user.projects = user.projects.map(project => new Project(project))
            if (state.user.projects.length === 0) {
                this.dispatch('logout')
                return
            }
            let currentProject = state.user.projects[0]
            const storedProject = JSON.parse(localStorage.getItem(LocalStorageProjectKey))
            // verify, if selected project in local storage is actually available for this user.
            for (const project of state.user.projects) {
                if (storedProject && project.id === storedProject.id) {
                    currentProject = project
                    break
                }
            }
            this.commit('activeProject', currentProject)
            SupportChatHelper.registerUserData(user.name, user.email, currentProject.name)
        },
        setAvailableFeatures(state, features) {
            state.availableFeatures = features
        },
        setProjectSettings(state, settings) {
            state.projectSettings = settings
        },
    },
    actions: {
        logout() {
            return new Promise(resolve => {
                Vue.prototype.$auth.logout()
                delete Axios.defaults.headers.common.Authorization
                // Vue.prototype.$router.push({ name: 'login' })
                resolve()
            })
        },
        fetchUser({ commit }) {
            // both ajax request have to happen one after the other one, then CANT!!! happen at once.
            return new Promise((resolve, reject) => {
                Axios({ url: `${process.env.VUE_APP_API_HTTP_ROOT}profile`, method: 'GET' })
                    .then(resp => {
                        commit('user', resp.data)
                        resolve()
                    })
                    .catch(err => {
                        this.dispatch('logout')
                        reject(err)
                    })
            }).then(() => {
                Axios({ url: `${process.env.VUE_APP_API_HTTP_ROOT}users/get-permissions-list`, method: 'POST' })
                    .then(resp => {
                        const permissionObject = Permission.generateChildPermissions(resp.data.permissions, resp.data.user_permissions)
                        commit('setPermissionList', permissionObject)
                    })
                    .catch(() => {
                        this.dispatch('logout')
                    })
            }).then(() => {
                this.dispatch('fetchProjectSettings')
            })
        },
        fetchProjectSettings({ commit }) {
            return Axios({ url: `${process.env.VUE_APP_API_HTTP_ROOT}project/get-settings`, method: 'POST' })
                .then(resp => {
                    const { settings } = resp.data
                    settings.loaded = true
                    commit('setProjectSettings', settings)
                    commit('setAvailableFeatures', resp.data.features)
                    SupportChatHelper.registerProjectFeatures(resp.data.features)
                })
                .catch(() => {
                    this.dispatch('logout')
                })
        },
        async changeProject({ commit }, project) {
            commit('activeProject', project)
        },
    },
    getters: {
        isLoggedIn: state => !!state.token,
        user: state => state.user,
        activeProject: state => state.activeProject,
        availableFeatures: state => state.availableFeatures,
        projectSettings: state => state.projectSettings,
    },
}
