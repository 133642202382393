<template>
    <b-container>
        <b-row
            class="vh-100 text-center"
            align-v="center"
        >
            <b-col>
                <b-img v-if="isDark" src="../../assets/img/logo/logo_default_light.png" alt="Fulfillin Logos" fluid />
                <b-img v-if="!isDark" src="../../assets/img/logo/logo_default_dark.png" alt="Fulfillin Logos" fluid />
                <br/>
                <b-spinner class="mt-5" style="width: 3rem; height: 3rem; margin-top: 20px;" variant="primary" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {
    BContainer,
    BRow,
    BCol,
    BImg,
    BSpinner,
} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BImg,
        BSpinner,
    },
    setup() {
        const { skin } = useAppConfig()
        const isDark = computed(() => skin.value === 'dark')
        return {
            isDark,
        }
    },
}
</script>
