import Axios from "axios"
import {CONDITION_NEW_ITEM} from "@/services/AmazonItemConditions"

const AMAZON_SHIPMENT_CREATION_LOCAL_STORAGE_KEY = 'amazonShipmentCreationStockQuantities'
const AMAZON_SHIPMENT_CREATION_CURRENT_MARKETPLACE_LOCAL_STORAGE_KEY = 'amazonShipmentCreationCurrentMarketplace'
const AMAZON_SHIPMENT_CREATION_FROM_ADDRESS_LOCAL_STORAGE_KEY = 'amazonShipmentFromAddress'
const AMAZON_SHIPMENT_CREATION_SHIPMENT_NAME_LOCAL_STORAGE_KEY = 'amazonShipmentName'
const AMAZON_SHIPMENT_CREATION_LABEL_PREP_PREFERENCE_LOCAL_STORAGE_KEY = 'amazonShipmentLabelPrepPreference'

export default {
    state: {
        amazonMarketplaces: {},
        currentAmazonMarketplaceId: null,
        amazonShipmentCreationStockQuantities: {},
        amazonShipmentFromAddress: {
            name: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            stateOrProvinceCode: '',
            countryCode: '',
            postalCode: '',
        },
        amazonShipmentName: '',
        amazonShipmentLabelPrepPreference: '',
    },
    getters: {
        getAmazonMarketplaces: state => state.amazonMarketplaces,
        getCurrentAmazonMarketplaceId: state => state.currentAmazonMarketplaceId,
        getAmazonShipmentCreationStockQuantities: state => state.amazonShipmentCreationStockQuantities,
        getAmazonShipmentFromAddress: state => state.amazonShipmentFromAddress,
        getAmazonShipmentName: state => state.amazonShipmentName,
        getAmazonShipmentLabelPrepPreference: state => state.amazonShipmentLabelPrepPreference,
    },
    mutations: {
        setAmazonMarketplaces(state, marketplaces) {
            state.amazonMarketplaces = marketplaces
        },
        setCurrentAmazonMarketplaceId(state, marketplaceId) {
            state.currentAmazonMarketplaceId = marketplaceId
        },
        setAmazonShipmentCreationStockQuantities(state, quantities) {
            state.amazonShipmentCreationStockQuantities = quantities
        },
        setAmazonShipmentFromAddress(state, address) {
            state.amazonShipmentFromAddress = address
        },
        setAmazonShipmentName(state, name) {
            state.amazonShipmentName = name
        },
        setAmazonShipmentLabelPrepPreference(state, labelPrepPreference) {
            state.amazonShipmentLabelPrepPreference = labelPrepPreference
        },
    },
    actions: {
        async setAmazonMarketplaces(state) {
            const response = await Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}amazon/get-available-marketplaces`,
                method: 'GET'
            })
            state.commit('setAmazonMarketplaces', response.data)
        },
        setInitialAmazonShipmentCreationStockQuantities(state) {
            const quantities = localStorage.getItem(AMAZON_SHIPMENT_CREATION_LOCAL_STORAGE_KEY) || {}
            state.commit('setAmazonShipmentCreationStockQuantities', JSON.parse(quantities))
        },
        setAmazonShipmentCreationStockQuantityForItem(state, {marketplaceId, itemId, listingId, quantity, quantityInBox, condition}) {
            if (quantityInBox === undefined) {
                quantityInBox = 1
            }
            if (condition === undefined) {
                condition = CONDITION_NEW_ITEM
            }
            const quantities = state.getters.getAmazonShipmentCreationStockQuantities

            if (!quantities[marketplaceId]) {
                quantities[marketplaceId] = {}
            }
            quantities[marketplaceId][itemId] = {listingId, quantity, quantityInBox, condition}
            localStorage.setItem(AMAZON_SHIPMENT_CREATION_LOCAL_STORAGE_KEY, JSON.stringify(quantities))
            state.commit('setAmazonShipmentCreationStockQuantities', quantities)
        },
        removeAmazonShipmentCreationItem(state, {marketplaceId, itemId}) {
            const quantities = state.getters.getAmazonShipmentCreationStockQuantities

            if (!quantities[marketplaceId]) {
                quantities[marketplaceId] = {}
            }
            delete quantities[marketplaceId][itemId]
            localStorage.setItem(AMAZON_SHIPMENT_CREATION_LOCAL_STORAGE_KEY, JSON.stringify(quantities))
            state.commit('setAmazonShipmentCreationStockQuantities', quantities)
        },
        loadCurrentAmazonMarketplaceId(state) {
            const marketplaceId = localStorage.getItem(AMAZON_SHIPMENT_CREATION_CURRENT_MARKETPLACE_LOCAL_STORAGE_KEY) || null
            state.commit('setCurrentAmazonMarketplaceId', marketplaceId)
        },
        setCurrentAmazonMarketplaceId(state, marketplaceId) {
            localStorage.setItem(AMAZON_SHIPMENT_CREATION_CURRENT_MARKETPLACE_LOCAL_STORAGE_KEY, marketplaceId)
            state.commit('setCurrentAmazonMarketplaceId', marketplaceId)
        },
        loadAmazonShipmentFromAddress(state) {
            const address = localStorage.getItem(AMAZON_SHIPMENT_CREATION_FROM_ADDRESS_LOCAL_STORAGE_KEY)
            state.commit('setAmazonShipmentFromAddress', JSON.parse(address))
        },
        setAmazonShipmentFromAddress(state, address) {
            localStorage.setItem(AMAZON_SHIPMENT_CREATION_FROM_ADDRESS_LOCAL_STORAGE_KEY, JSON.stringify(address))
            state.commit('setAmazonShipmentFromAddress', address)
        },
        loadAmazonShipmentName(state) {
            const name = localStorage.getItem(AMAZON_SHIPMENT_CREATION_SHIPMENT_NAME_LOCAL_STORAGE_KEY)
            state.commit('setAmazonShipmentName', JSON.parse(name))
        },
        setAmazonShipmentName(state, name) {
            localStorage.setItem(AMAZON_SHIPMENT_CREATION_SHIPMENT_NAME_LOCAL_STORAGE_KEY, JSON.stringify(name))
            state.commit('setAmazonShipmentName', name)
        },
        loadAmazonShipmentLabelPrepPreference(state) {
            const labelPrepPreference = localStorage.getItem(AMAZON_SHIPMENT_CREATION_LABEL_PREP_PREFERENCE_LOCAL_STORAGE_KEY)
            state.commit('setAmazonShipmentLabelPrepPreference', JSON.parse(labelPrepPreference))
        },
        setAmazonShipmentLabelPrepPreference(state, labelPrepPreference) {
            localStorage.setItem(AMAZON_SHIPMENT_CREATION_LABEL_PREP_PREFERENCE_LOCAL_STORAGE_KEY, JSON.stringify(labelPrepPreference))
            state.commit('setAmazonShipmentLabelPrepPreference', labelPrepPreference)
        },
    },
}
