import { $themeConfig } from '@themeConfig'

export default {
    namespaced: true,
    state: {
        isVerticalMenuCollapsed: localStorage.getItem('vertical_collapsed') || $themeConfig.layout.menu.isCollapsed,
        isHelpVisible: parseInt(localStorage.getItem('help_visible')) || false,
    },
    getters: {},
    mutations: {
        UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
            state.isVerticalMenuCollapsed = val

            localStorage.setItem('vertical_collapsed', val)
        },
        SET_HELP_VISIBLE(state, val) {
            state.isHelpVisible = val

            localStorage.setItem('help_visible', val ? '1' : '0')
        },
    },
    actions: {},
}
