import Vue from 'vue'
import Axios from 'axios'
import router from '@/router'

let instance

export const getInstance = () => instance

export const useCognito = ({
    ...options
}) => {
    if (instance) return instance

    instance = new Vue({
        data() {
            return {
                loading: true,
                isAuthenticated: false,
                requiresAuth: false,
                error: null,
            }
        },
        methods: {
            logout() {
                localStorage.removeItem('accessToken')
                window.location.href = `${options.domain}/logout?response_type=${options.responseType}&client_id=${options.clientId}&redirect_uri=${options.redirectUrl}&state=${btoa(this.getTransferableState())}`
            },
            redirectToLogin() {
                localStorage.removeItem('accessToken')
                window.location.href = `${options.domain}/oauth2/authorize?response_type=${options.responseType}&client_id=${options.clientId}&redirect_uri=${options.redirectUrl}&state=${btoa(this.getTransferableState())}`
            },
            async verifyAuthCode() {
                try {
                    const hasCode = window.location.search.includes('code=')
                    if (hasCode) {
                        const urlParams = new URLSearchParams(window.location.search)
                        const authCode = urlParams.get('code')
                        const storedUrlState = JSON.parse(atob(urlParams.get('state')))

                        try {
                            const resp = await Axios({
                                url: `${process.env.VUE_APP_API_HTTP_ROOT}get-auth-token/${authCode}`,
                                method: 'GET'
                            })
                            this.$http.defaults.headers.common.Authorization = `Bearer ${resp.data.token}`
                            localStorage.setItem('accessToken', resp.data.token)
                            this.isAuthenticated = true
                            // remove code param
                            window.history.replaceState({}, document.title, '/')
                            let urlToRedirect = storedUrlState && storedUrlState.url ? storedUrlState.url : '/'
                            if (urlToRedirect.startsWith('//#')) {
                                urlToRedirect = urlToRedirect.substring(3)
                            }
                            router.push(urlToRedirect)
                        } catch (err) {
                            this.redirectToLogin()
                        }
                    }
                } catch (e) {
                    this.error = e
                } finally {
                    this.loading = false
                }
            },
            checkIfTokenIsStored() {
                const token = localStorage.getItem('accessToken')
                if (token) {
                    this.$http.defaults.headers.common.Authorization = `Bearer ${token}`
                    this.isAuthenticated = true
                    this.loading = false
                }
            },
            getTransferableState () {
                const url = window.location.href.substring(window.location.origin.length)
                let successfulLoginRedirectUrl = url.startsWith('/') ? url : `/${window.location.href.substring(window.location.origin.length)}`

                return `{"url":"${successfulLoginRedirectUrl}"}`
            }
        },
    })

    return instance
}

export const AuthPlugin = {
    install(Vue1, options) {
        Vue.prototype.$auth = useCognito(options)
    },
}
