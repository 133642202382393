import State from '@/store/index'

export default class Access {
    static get LEVEL_NONE() {
        return 0
    }

    static get LEVEL_READ() {
        return 1
    }

    static get LEVEL_WRITE() {
        return 2
    }

    static get permissions() {
        return this._perms
    }

    static set permissions(perms) {
        this._perms = perms
    }

    static checkAccess(perm, level) {
        if (typeof perm === 'string') {
            return Access._checkAccess(perm, level)
        }
        if (Array.isArray(perm)) {
            for (const singlePerm of perm) {
                if (Access.checkAccess(singlePerm, level)) {
                    return true
                }
            }
        }

        return false
    }

    static _checkAccess(perm, level) {
        if (Object.keys(State.getters.permissionList).length === 0) {
            return false
        }
        if (!Access.permissions) {
            Access.permissions = {}
            Access._setPermissions()
        }

        return typeof Access.permissions[perm] === 'number' && Access.permissions[perm] >= level
    }

    static _setPermissions() {
        Access._loopPermissions(State.getters.permissionList, [])
    }

    static _loopPermissions(permissionList, parentLevelKeys) {
        const keys = Object.keys(permissionList)
        for (const key of keys) {
            const permission = permissionList[key]
            const permissionKeys = parentLevelKeys.concat([key])
            const permissionKeyString = permissionKeys.join('.')
            Access.permissions[permissionKeyString] = permission.currentLevel
            Access._loopPermissions(permission.children, permissionKeys)
        }
    }
}
