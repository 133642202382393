<template>
    <div v-if="visible" class="modal" role="dialog" style="display: block;">
        <div ref="modal" class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <header id="modal-primary___BV_modal_header_" class="modal-header">
                    <h5 id="modal-primary___BV_modal_title_" class="modal-title">
                        Set default project settings
                    </h5>
                    <button type="button" aria-label="Close" class="close" @click="close">
                        ×
                    </button>
                </header>
                <div class="modal-body">
                    <b-form>
                        <b-form-row>
                            <b-col md="9" offset-md="1">
                                <b-form-group
                                    label="Project name"
                                    label-for="s-project-name"
                                    label-cols-md="4"
                                >
                                    <b-form-input v-model="name"/>
                                </b-form-group>

                            </b-col>
                        </b-form-row>
                    </b-form>
                    <div class="alert alert-danger p-1" v-if="error">
                        Please provide project name
                    </div>
                </div>
                <footer class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="close">
                        Close
                    </button>
                    <button type="button" class="btn btn-primary" :disabled="loading" @click="save">
                        {{ loading ? 'Saving...' : 'Save' }}
                    </button>
                </footer>

            </div>
        </div>
    </div>
</template>

<style>
.modal {
    background: rgba(0, 0, 0, 0.3);
}
</style>

<script>
import store from '@/store'
import {
    BFormRow, BCol, BFormGroup, BForm, BFormInput
} from 'bootstrap-vue'
import Axios from "axios"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BFormRow, BCol, BFormGroup, BForm, BFormInput,
    },
    data() {
        return {
            loading: false,
            visible: false,
            name: '',
            error: false,
        }
    },
    computed: {
        projectSettings() {
            return store.getters.projectSettings
        },
        activeProject() {
            return store.getters.activeProject
        }
    },
    created() {
        if (!this.disableEnterConfirm) {
            window.addEventListener('keyup', this.handleKeyEvent)
        }
        this.name = this.activeProject.name
        this.visible = !this.projectSettings.nameSet
    },
    methods: {
        handleKeyEvent(event) {
            if (this.visible) {
                if (event.keyCode === 27) {
                    this.close()
                }
                if (event.keyCode === 13) {
                    this.save()
                }
            }
        },
        close() {
            this.visible = false
        },
        async save() {
            this.loading = true
            this.error = false
            const currentSettings = this.projectSettings
            currentSettings.nameSet = true
            const data = {
                ...currentSettings,
                name: this.name,
            }

            try {
                await Axios({
                    url: `${process.env.VUE_APP_API_HTTP_ROOT}project/set-settings`,
                    data: data,
                    method: 'POST',
                })
                await this.$store.dispatch('fetchProjectSettings')
                await this.$store.dispatch('fetchProjectSettings')
                this.loading = false
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Success!',
                        icon: 'CheckIcon',
                        text: 'Project settings saved successfully.',
                        variant: 'primary',
                    },
                })
                this.visible = false
                window.location.reload()
            } catch (e) {
                this.loading = false
                this.error = true

            }


        }
    },
}
</script>
