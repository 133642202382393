import Access from "@/services/Access"

const layoutAuth = 'auth'

export default [
    // Auth stuff
    {
        path: '/login',
        name: 'login',
        requiresAuth: true,
        component: () => import('./views/auth/Login.vue'),
    },

    // Dashboard
    {
        path: '/',
        name: 'dashboard',
        meta: {
            requiresAuth: true,
            requiresAccess: false,
            breadcrumb: [
                {
                    text: 'Main page',
                    active: true,
                },
            ],

        },
        component: () => import('./views/Dashboard.vue'),
    },
    {
        path: '/dashboard/linnworks',
        name: 'dashboard.linnworks',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            breadcrumb: [
                {
                    text: 'Shipments dashboard',
                    active: true,
                },
            ],

        },
        component: () => import('./views/Dashboards/LinnworksDashboard.vue'),
    },
    {
        path: '/dashboard/mirakl',
        name: 'dashboard.mirakl',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            breadcrumb: [
                {
                    text: 'Mirakl dashboard',
                    active: true,
                },
            ],

        },
        component: () => import('./views/Dashboards/MiraklDashboard.vue'),
    },
    {
        path: '/dashboard/schenker',
        name: 'dashboard.schenker',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            breadcrumb: [
                {
                    text: 'Schenker dashboard',
                    active: true,
                },
            ],

        },
        component: () => import('./views/Dashboards/SchenkerDashboard.vue'),
    },
    // pie
    {
        path: '/pie',
        name: 'pie',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            breadcrumb: [
                {
                    text: 'Dashboard',
                    active: true,
                },
            ],
        },
        component: () => import('./views/Pie.vue'),
    },
    // Settings
    {
        path: '/settings/users',
        name: 'settings.users',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            access: {
                key: 'settings.users',
                level: Access.LEVEL_READ,
            },
            pageTitle: 'Users list',
            breadcrumb: [
                {
                    text: 'Users',
                    active: true,
                },
            ],
        },
        component: () => import('./views/settings/Users/Users.vue'),
    },
    {
        path: '/settings/users/create',
        name: 'settings.users.create',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            access: {
                key: 'settings.users',
                level: Access.LEVEL_WRITE,
            },
            pageTitle: 'Create user',
            breadcrumb: [
                {
                    text: 'Users',
                    active: false,
                    to: '/settings/users',
                },
                {
                    text: 'Create user',
                    active: true,
                },
            ],
        },
        component: () => import('./views/settings/Users/CreateUser.vue'),
    },
    {
        path: '/settings/users/permissions/:id',
        name: 'settings.users.permissions',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            access: {
                key: 'settings.users',
                level: Access.LEVEL_WRITE,
            },
            pageTitle: 'Edit user\'s permissions',
            breadcrumb: [
                {
                    text: 'Users',
                    active: false,
                    to: '/settings/users',
                },
                {
                    text: 'Edit user\'s permissions',
                    active: true,
                },
            ],
        },
        component: () => import('./views/settings/Users/Permissions.vue'),
    },
    {
        path: '/settings/user-groups',
        name: 'settings.user-groups',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            access: {
                key: 'settings.user_groups',
                level: Access.LEVEL_READ,
            },
            pageTitle: 'User groups',
            breadcrumb: [
                {
                    text: 'Users',
                    active: false,
                    to: '/settings/users'
                },
                {
                    text: 'User groups',
                    active: true,
                },
            ],
        },
        component: () => import('./views/settings/UserGroups/Index.vue'),
    },
    {
        path: '/settings/user-groups/create',
        name: 'settings.user-groups.create',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            access: {
                key: 'settings.user_groups',
                level: Access.LEVEL_WRITE,
            },
            pageTitle: 'Create new user group',
            breadcrumb: [
                {
                    text: 'Users',
                    active: false,
                    to: '/settings/users'
                },
                {
                    text: 'User groups',
                    active: false,
                    to: '/settings/user-groups'
                },
                {
                    text: 'Create user group',
                    active: true,
                }
            ],
        },
        component: () => import('./views/settings/UserGroups/Create.vue'),
    },
    {
        path: '/settings/user-groups/edit/:id',
        name: 'settings.user-groups.edit',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            access: {
                key: 'settings.user_groups',
                level: Access.LEVEL_WRITE,
            },
            pageTitle: 'Edit user group',
            breadcrumb: [
                {
                    text: 'Users',
                    active: false,
                    to: '/settings/users'
                },
                {
                    text: 'User groups',
                    active: false,
                    to: '/settings/user-groups'
                },
                {
                    text: 'Edit user group',
                    active: true,
                }
            ],
        },
        component: () => import('./views/settings/UserGroups/Edit.vue'),
    },
    {
        path: '/settings/project',
        name: 'settings.project',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            access: {
                key: 'settings.project',
                level: Access.LEVEL_READ,
            },
            breadcrumb: [
                {
                    text: 'Project settings',
                    active: true,
                },
            ],
        },
        component: () => import('./views/settings/Project.vue'),
    },
    {
        path: '/settings/custom-fields',
        name: 'settings.customFields',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            access: {
                key: 'settings.customFields',
                level: Access.LEVEL_READ,
            },
            breadcrumb: [
                {
                    text: 'Custom fields',
                    active: true,
                },
            ],
        },
        component: () => import('./views/settings/CustomFields.vue'),
    },
    {
        path: '/settings/integrations',
        name: 'settings.integrations', // int
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            access: {
                key: 'settings.integrations',
                level: Access.LEVEL_READ,
            },
            pageTitle: 'Integrations',
            breadcrumb: [
                {
                    text: 'Integrations',
                    active: true,
                },
            ],
        },
        component: () => import('./views/settings/Integrations.vue'),
    },
    {
        path: '/settings/inventory',
        name: 'settings.inventory',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            access: {
                key: 'settings.inventory',
                level: Access.LEVEL_READ,
            },
            pageTitle: 'Inventory settings',
            breadcrumb: [
                {
                    text: 'Inventory settings',
                    active: true,
                },
            ],
        },
        component: () => import('./views/settings/Inventory.vue'),
    },
    {
        path: '/settings/orders',
        name: 'settings.orders',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            access: {
                key: 'settings.orders',
                level: Access.LEVEL_READ,
            },
            pageTitle: 'Orders settings',
            breadcrumb: [
                {
                    text: 'Orders settings',
                    active: true,
                },
            ],
        },
        component: () => import('./views/settings/Orders.vue'),
    },

    // Profile
    {
        path: '/settings/profile',
        name: 'settings.profile',
        meta: {
            requiresAuth: true,
            requiresAccess: false,
            pageTitle: 'Profile',
            breadcrumb: [
                {
                    text: 'Profile',
                    active: true,
                },
            ],
        },
        component: () => import('./views/settings/Profile.vue'),
    },
    // Shipments
    {
        path: '/shipments',
        name: 'shipments.index',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            access: {
                key: 'shipments',
                level: Access.LEVEL_READ,
            },
            pageTitle: 'Shipments',
            breadcrumb: [
                {
                    text: 'Shipments',
                    active: true,
                },
            ],
        },
        component: () => import('./views/Shipments/Index.vue'),
    },
    {
        path: '/shipments/view/:id',
        name: 'shipments.view',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            access: {
                key: 'shipments',
                level: Access.LEVEL_READ,
            },
            pageTitle: 'Shipment view',
            breadcrumb: [
                {
                    text: 'Shipments',
                    active: false,
                    to: '/shipments'
                },
                {
                    text: 'Shipment view',
                    active: true,
                }
            ],
        },
        component: () => import('./views/Shipments/View.vue'),
    },
    {
        path: '/shipment-plans',
        name: 'shipments.shipmentPlans',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            requiresFeature: true,
            feature: 'shipment_plans',
            access: {
                key: 'shipments',
                level: Access.LEVEL_READ,
            },
            pageTitle: 'Shipment plans',
            breadcrumb: [
                {
                    text: 'Shipments',
                    active: true,
                },
                {
                    text: 'Shipment plans',
                    active: true,
                },
            ],
        },
        component: () => import('./views/Shipments/ShipmentPlans.vue'),
    },
    {
        path: '/shipment-plans/view-amazon/:id',
        name: 'shipments.viewAmazonShipmentPlan',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            requiresFeature: true,
            feature: 'shipment_plans',
            access: {
                key: 'shipments',
                level: Access.LEVEL_READ,
            },
            pageTitle: 'View spipment plan',
            breadcrumb: [
                {
                    text: 'Shipments',
                    active: false,
                },
                {
                    text: 'Shipment plans',
                    active: false,
                    to: {name: 'shipments.shipmentPlans'}
                },
                {
                    text: 'View Amazon Shipment Plan',
                    active: true,
                },
            ],
        },
        component: () => import('./views/Shipments/ViewShipmentPlan.vue'),
    },

    {
        path: '/inventory',
        name: 'inventory.index',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            access: {
                key: 'inventory',
                level: Access.LEVEL_READ,
            },
            requiresFeature: true,
            feature: 'inventory',
            pageTitle: 'Inventory',
            breadcrumb: [
                {
                    text: 'Inventory',
                    active: true
                },
            ],
        },
        component: () => import('./views/Inventory/Index.vue'),
    },
    {
        path: '/inventory/create-amazon-shipment',
        name: 'inventory.createAmazonShipment',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            requiresFeature: true,
            feature: 'shipment_plans',
            access: {
                key: 'shipments',
                level: Access.LEVEL_READ,
            },
            pageTitle: 'Create Amazon Shipment',
            breadcrumb: [
                {
                    text: 'Inventory',
                    active: false,
                    to: '/inventory'

                },
                {
                    text: 'Create Amazon Shipment',
                    active: true,
                },
            ],
        },
        component: () => import('./views/Inventory/CreateAmazonShipment.vue'),
    },
    {
        path: '/orders',
        name: 'orders.index',
        meta: {
            requiresAuth: true,
            requiresAccess: true,
            access: {
                key: 'orders',
                level: Access.LEVEL_READ,
            },
            requiresFeature: true,
            feature: 'orders',
            pageTitle: 'Orders',
            breadcrumb: [
                {
                    text: 'Orders',
                    active: true
                },
            ],
        },
        component: () => import('./views/Orders/Index.vue'),
    },

    // Error pages
    {
        path: '*',
        name: '404',
        meta: {
            layout: layoutAuth,
        },
        component: () => import('./views/404.vue'),
    },
]