export default class Permission {
    /**
     * @param name
     * @param path
     * @param children
     * @param maxLevel
     * @param currentLevel
     */
    constructor({
        name, path, children, maxLevel, currentLevel,
    }) {
        this.name = name
        this.path = path
        this.children = children
        this.max_level = maxLevel // hacky, but the key must be exactly the same as in backend - DB column name
        this.currentLevel = currentLevel
    }

    static generateChildPermissions(permissions, userPermissions) {
        return Permission.recursiveBuildPermissions(permissions, userPermissions)
    }

    static recursiveBuildPermissions(perms, userPermissions) {
        const output = {}
        for (const key in perms) {
            // eslint-disable-next-line no-prototype-builtins
            if (!perms.hasOwnProperty(key)) {
                continue
            }
            const perm = perms[key]
            let currentLevel = 0
            if (userPermissions[key] && typeof userPermissions[key] === 'number') {
                currentLevel = userPermissions[key]
            }

            output[key] = new Permission({
                name: key,
                path: perm.path,
                maxLevel: perm.max_level,
                children: Permission.recursiveBuildPermissions(perm.children, userPermissions[key] ? userPermissions[key] : {}),
                currentLevel,
            })
        }

        return output
    }

    static prepareForSaving(permissions) {
        return Permission._recursivePrepareForSaving(permissions, {}, [])
    }

    static _recursivePrepareForSaving(permissions, preparedPermissions, parentArray) {
        for (const perm in permissions) {
            // eslint-disable-next-line no-prototype-builtins
            if (!permissions.hasOwnProperty(perm)) {
                continue
            }
            const localArray = JSON.parse(JSON.stringify(parentArray))
            localArray.push(perm)
            preparedPermissions[localArray.join('.')] = permissions[perm].currentLevel

            preparedPermissions = Permission._recursivePrepareForSaving(permissions[perm].children, preparedPermissions, localArray)
        }

        return preparedPermissions
    }
}
