import State from '@/store/index'

export default class Features {
    static get availableFeatures() { return this._availableFeatures }

    static set availableFeatures(features) { this._availableFeatures = features }

    static checkFeature(feature) {
        if (typeof feature !== 'string') {
            return false
        }

        if (!Features.availableFeatures) {
            Features.availableFeatures = State.getters.availableFeatures
        }

        return Features.availableFeatures.indexOf(feature) !== -1
    }
}
