import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import Axios from 'axios'
import App from './AppAuthWraper.vue'
import i18n from './libs/i18n'
import router from './router'
import store from './store/index'
import { AuthPlugin } from './auth'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import * as Sentry from '@sentry/vue'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// HTTP plugin
Vue.prototype.$http = Axios

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
const domain = process.env.VUE_APP_AUTH_DOMAIN
const clientId = process.env.VUE_APP_AUTH_CLIENT_ID
const responseType = process.env.VUE_APP_AUTH_RESPONSE_TYPE
const redirectUrl = process.env.VUE_APP_AUTH_REDIRECT_URL


Vue.use(AuthPlugin, {
    domain,
    clientId,
    responseType,
    redirectUrl,
})

if (process.env.NODE_ENV !== 'development' && window.location.protocol === 'http:') {
    window.location.href = window.location.href.replace('http://', 'https://')
}

Vue.config.productionTip = false

// set default project header value. This is ok for global endpoints, like login and get profile.
// valid project ID is mandatory for project related endpoints like getting lists or project users
Vue.prototype.$http.defaults.headers.common['X-XcProject'] = 0

Vue.prototype.$http.interceptors.response.use(response => response,
    error => {
        if (error.response.status === 401) {
        // log out user. Might implement refresh token here later
            store.dispatch('logout')
        }

        return Promise.reject(error)
    })

const sentryDSN = process.env.VUE_APP_SENTRY_DSN ?? null

if (sentryDSN) {
    Sentry.init({
        Vue,
        dsn: sentryDSN,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["*"],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
    })
}


new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
