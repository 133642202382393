export default class Project {
    /**
     * @param id
     * @param name
     * @param isActive
     */
    constructor({ id, name, isActive }) {
        this.id = id
        this.name = name
        this.isActive = isActive
    }
}
