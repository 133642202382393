export default {
    state: {
        permissionList: {},
    },

    getters: {
        permissionList: state => state.permissionList,
    },
    mutations: {
        setPermissionList(state, permissions) {
            state.permissionList = permissions
        },
    },
    actions: {},
}
