export default class SupportChatHelper {
    static registerUserData(name, email, projectName) {
        // eslint-disable-next-line no-undef
        customerly.update({
            name,
            email,
            attributes: {
                project_name: projectName
            }
        })
    }

    static registerProjectFeatures(features) {
        // eslint-disable-next-line no-undef
        customerly.attribute('project_features', features.join(', '))
    }

    static showWidget() {
        customerly.update({
            visible: true,
            visibleOnMobile: true,
        })
    }

    static expandWidget() {
        this.showWidget()
        customerly.open()
    }

    static hideWidget() {
        customerly.update({
            visible: false,
            visibleOnMobile: false,
        })
    }
}
